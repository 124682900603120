import React, {useState, useEffect} from 'react'
import { XIcon } from '@heroicons/react/outline'
// import { AcademicCapIcon } from '@heroicons/react/solid'

export default function BannerBottomFix() {

  const [display, setDisplay] = useState(false)
  const [position, setPosition] = useState('-bottom-28 md:-bottom-24')

  const displayComponent = () => {
    setDisplay(true) 
    setTimeout(() => {
      setPosition('bottom-5 md:bottom-10')
    }, 300);
  }

  useEffect(() => {
    let timeout = setTimeout(() => displayComponent(), 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, [])

  if(display){
    return (
      <div className={`fixed ${position} bottom inset-x-0 pb-2 sm:pb-5 z-50 animateBanner`}>
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="p-2 rounded-lg bg-gray-900 bg-opacity-96 shadow-lg sm:p-3">
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                {/* <span className="flex p-2 rounded-lg bg-indigo-800">
                  <AcademicCapIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span> */}
                <p className="ml-3 font-medium text-white truncate">
                  <span className="md:hidden">Je forme des développeurs juniors</span>
                  <span className="hidden md:inline">
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-semibold bg-indigo-400 text-indigo-800 mr-3">
                      News
                    </span>
                    Envie de te former au métier de développeur Front-end ? Découvre mes formations en ligne.
                  </span>
                </p>
              </div>
              <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                <a
                  href="https://formations.arnaud-desportes.fr/"
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-semibold text-white bg-indigo-600 hover:bg-green-500 animate uppercase"
                >
                  Découvrir
                </a>
              </div>
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                <button
                  onClick={() => setDisplay(false)}
                  type="button"
                  className="-mr-1 flex p-2 rounded-md hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-white animate"
                >
                  <span className="sr-only">Fermer</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  else {
    return(
      <></>
    )
  }
}