import React, {useState, useEffect} from 'react';
import { BeatLoader } from 'react-spinners';
import Logo from '../assets/images/ImagesSociete/Logo-webSite-AD.svg';

const LaunchScreen = () => {

  const [ready, setReady] = useState(false)

  const timeOut = () => {
    setTimeout(function(){
    }, 800)
  }

  useEffect(() => {
    timeOut()
    return () => {
      setReady(true)
    }
  }, [])

  return (
    <div className="h-screen w-full flex items-center justify-center text-gray-700 text-sm">

      {ready ?
        <div className="flex flex-col items-center justify-center">
          <img className="w-14 mb-4" alt="AD"
            title="Arnaud Desportes, developpeur web Front-End Freelance"
            src={Logo}
          />
          <BeatLoader size="10" color="#5a67d8" />
        </div>
      : null }

    </div>
  );
}

export default LaunchScreen;



