/**
 * Traitement des routes du site web.
 */
import React, { lazy, Suspense } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Route, Switch, useLocation } from 'react-router-dom'
import LaunchScreen from './utils/LaunchScreen'
import BannerBottom from './components/Elements/Banners/BannerBottom'

/* component page */
const HomePage = lazy  (() => import('./pages/Home/HomePage'))
const ServicesPage = lazy  (() => import('./pages/Services/ServicesPage'))
const ParcoursPage = lazy  (() => import('./pages/Parcours/ParcoursPage'))
const SkillsPage = lazy  (() => import('./pages/Skills/SkillsPage'))
const PricesPage = lazy  (() => import('./pages/Prices/PricesPage'))
const PortfolioPage = lazy  (() => import('./pages/Portfolio/PortfolioPage'))
const ProjectsPage = lazy  (() => import('./pages/Portfolio/ProjectsPage'))
const FeedBackPage = lazy  (() => import('./pages/Feedback/FeedBackPage.js'))
const ContactPage = lazy  (() => import('./pages/Contact/ContactPage'))
const ErrorPage = lazy  (() => import('./pages/Error404/ErrorPage'))

const Routes = () => {

  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 1
  };

  return (
    <>
      <AnimatePresence>
        <Suspense fallback={<LaunchScreen />}>

          <BannerBottom />

          <Switch location={location} key={location.pathname}>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}>

              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route path="/services" component={ServicesPage} />
                <Route path="/parcours" component={ParcoursPage} />
                <Route path="/competences" component={SkillsPage} />
                <Route path="/tarifs" component={PricesPage} />
                <Route exact path="/portfolio" component={PortfolioPage} />
                <Route path="/portfolio/:idProject" component={ProjectsPage} />
                <Route path="/recommandations" component={FeedBackPage} />

                <Route path="/contact" component={ContactPage} />
                <Route component={ErrorPage} />
              </Switch>

            </motion.div>
          </Switch>

        </Suspense>

      </AnimatePresence>
    </>
  );
}

export default Routes;