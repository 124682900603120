import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/Utils/ScrollToTop';
import Routes from './Routes';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { 
  faHome,
  faRocket,
  faHamburger,
  faLaptopHouse,
  faGraduationCap,
  faNetworkWired,
  faCheck,
  faArrowRight,
  faFileCode,
  faLayerGroup,
  faQuestionCircle,
  faChalkboardTeacher,
  faHeart,
  faPaintBrush,
  faLaptopCode,
  faCode,
  faMugHot,
  faChevronCircleDown,
  faCodeBranch,
  faCalendarCheck,
  faEnvelopeOpenText,
  faFileInvoiceDollar,
  faWindowMaximize,
  faStore,
  faBullseye,
  faArrowLeft,
  faPhone,
  faInfo,
  faUserAstronaut,
  faTachometerAlt,
  faFileAlt,
  faCalculator,
  faStopwatch,
  faVideo,
  faUserShield,
  faFileDownload,

} from '@fortawesome/free-solid-svg-icons';

library.add(fab, 
  faHome,
  faRocket,
  faHamburger,
  faLaptopHouse,
  faGraduationCap,
  faNetworkWired,
  faCheck,
  faArrowRight,
  faArrowLeft,
  faFileCode,
  faLayerGroup,
  faQuestionCircle,
  faChalkboardTeacher,
  faHeart,
  faPaintBrush,
  faLaptopCode,
  faCode,
  faMugHot,
  faChevronCircleDown,
  faCodeBranch,
  faCalendarCheck,
  faEnvelopeOpenText,
  faFileInvoiceDollar,
  faWindowMaximize,
  faStore,
  faBullseye,
  faPhone,
  faInfo,
  faLaptopCode,
  faUserAstronaut,
  faTachometerAlt,
  faFileAlt,
  faCalculator,
  faStopwatch,
  faVideo,
  faUserShield,
  faFileDownload

);

function App() {

  // if (process.env.NODE_ENV === 'production') {
  //   console.log("production mode")
  // } else {
  //   console.log("development mode")
  // }
  
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes />
    </BrowserRouter> 
  );
}

export default App;
